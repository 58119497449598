<mat-drawer-container class="h-100">
    <div fxLayout="row" fxLayoutAlign="center center" class="h-100">
        <div fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">
            <mat-card  class="p-0 mat-elevation-z16">
                <div fxLayout="column" fxLayoutAlign="center center" class="bg-primary py-4 white-color">
                    <mat-icon class="mat-icon-xlg">error</mat-icon>
                    <h1 class="error">404</h1>
                </div>
                <mat-card-content fxLayout="column" fxLayoutAlign="end center">
                    <mat-card  fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z8 box">
                        <p>Opps, it seems that this page does not exist.</p> 
                        <p>If you are sure it should, search for it.</p> 
                        <mat-form-field class="w-100">
                            <input matInput placeholder="Enter search keyword...">
                        </mat-form-field>
                    </mat-card>
                    <div class="px-2 py-3">
                        <button mat-raised-button color="primary" class="mat-elevation-z8 mx-1" type="button" (click)="goHome()">
                            <mat-icon>home</mat-icon>
                        </button>
                        <button mat-raised-button color="primary" class="mat-elevation-z8 mx-1" type="button" (click)="goHome()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>          
        </div>
    </div>
<mat-drawer-container class="h-100">